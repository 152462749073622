const body = document.querySelector("body");

const burgerBtn = document.querySelector("[data-type='burger']");
const nav = document.querySelector("[data-type='nav']");
const info = document.querySelector("[data-type='info']");
const ctaForm = document.querySelector("[data-type='cta-form']");
const thanksPopup = document.querySelector(".thanks-popup");
const thanksPopupClose = document.querySelector("[data-type='thanks-close']");

thanksPopupClose.addEventListener("click", () => {
  thanksPopup.classList.remove("isActive");
});

const validateRegNumber =
  /^(\+7|7|8) ?\(?[0-9]{3}\)?[-| ]?[0-9]{3}[-| ]?[0-9]{2}[-| ]?[0-9]{2}$/;

async function formSend(e) {
  e.preventDefault();

  let validate = formValidate(e.target);
  let formData = new FormData(e.target);

  if (validate) {
    e.target.classList.add("_sending");

    let response = await fetch("sendmail.php", {
      method: "POST",
      body: formData,
    });

    console.log(response);
    if (response.ok) {
      let result = await response.json();
      e.target.reset();
      thanksPopup.classList.add("isActive");

      e.target.classList.remove("_sending");
    }
  }
}
const formValidate = (form) => {
  let error = 0;
  const sendingFormReq = form.querySelectorAll("._req");

  sendingFormReq.forEach((item, i) => {
    const input = item;

    formRemoveError(input);

    if (input.type == "checkbox" && !input.checked) {
      formAddError(input);
      error++;
    }

    if (input.closest("._number")) {
      const numberCheck = validateRegNumber.test(input.value);
      if (!numberCheck) {
        formAddError(input);
        error++;
      }
    } else if (input.value === "") {
      formAddError(input);
      error++;
    } else if (input.value.split("").length > 30) {
      let iValue = input.value.split("");
      formAddError(input);
      error++;
    }
  });

  if (error > 0) {
    return false;
  } else {
    return true;
  }
};
const formAddError = (input) => {
  input.parentElement.classList.add("_error");
  input.classList.add("_error");
};
const formRemoveError = (input) => {
  input.parentElement.classList.remove("_error");
  input.classList.remove("_error");
};

ctaForm.addEventListener("submit", formSend);
burgerBtn.addEventListener("click", (e) => {
  burgerBtn.classList.toggle("isActive");

  nav.classList.toggle("isActive");
  info.classList.toggle("isActive");

  body.classList.toggle("isHidden");

  nav.addEventListener("click", (e) => {
    if (e.target.classList.contains("nav__link")) {
      burgerBtn.classList.remove("isActive");
      nav.classList.remove("isActive");
      info.classList.remove("isActive");
      body.classList.remove("isHidden");
    }
  });
});

// кнопка наверх

const btnUp = {
  el: document.querySelector(".to_top"),
  show() {
    // удалим у кнопки класс btn-up_hide
    this.el.classList.remove("to_top_hide");
  },
  hide() {
    // добавим к кнопке класс btn-up_hide
    this.el.classList.add("to_top_hide");
  },
  addEventListener() {
    // при прокрутке содержимого страницы
    window.addEventListener("scroll", () => {
      // определяем величину прокрутки
      const scrollY = window.scrollY || document.documentElement.scrollTop;
      // если страница прокручена больше чем на 400px, то делаем кнопку видимой, иначе скрываем
      scrollY > 400 ? this.show() : this.hide();
    });
    // при нажатии на кнопку .btn-up
    document.querySelector(".to_top").onclick = () => {
      // переместим в начало страницы
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };
  },
};

btnUp.addEventListener();

// Документы

const documentsBtn = document.querySelector("[data-type='documents']");
const documentsPopup = document.querySelector(".documents-popup");
const documentPopup = document.querySelector(".document-popup");

documentsBtn.addEventListener("click", (event) => {
  documentsPopup.style.opacity = 1;
  documentsPopup.style.visibility = "visible";
  body.style.overflow = "hidden";
});

documentsPopup.addEventListener("click", (event) => {
  if (event.target.closest(".documents-popup__item-link")) {
    event.preventDefault();

    let target = event.target;

    while (!target.classList.contains("documents-popup__item-link")) {
      target = target.parentElement;
    }

    let sourceLink = target.querySelector("source").getAttribute("srcset");
    let imgLink = target.querySelector("img").getAttribute("src");
    let name = target.querySelector("span").textContent;

    let documentPopupTitle = documentPopup.querySelector("h2");
    let documentPopupImg = documentPopup.querySelector("img");
    let documentPopupSource = documentPopup.querySelector("source");

    documentPopupTitle.textContent = name;
    documentPopupSource.setAttribute("srcset", sourceLink);
    documentPopupImg.setAttribute("src", imgLink);

    documentPopup.style.opacity = 1;
    documentPopup.style.visibility = "visible";
  } else {
    documentsPopup.style.opacity = 0;
    documentsPopup.style.visibility = "hidden";
    body.style.overflow = "visible";
  }
});

documentPopup.addEventListener("click", (event) => {
  if (
    !event.target.classList.contains("document-popup__item-img") &&
    !event.target.classList.contains("document-popup__title")
  ) {
    documentPopup.style.opacity = 0;
    documentPopup.style.visibility = "hidden";
  }
});
